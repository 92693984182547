namespace aq.reportCenter {
    export class ReportCenterCtrl {
        public menuItems;
        constructor(
            private $scope,
            private $q,
            private baseReports
        ) {
            // This url used both for CSV download and for scheduling reports
            this.$scope.csvUrl = (item, path) => {
                const csvDownloadUrl = URI('/api/v1/accounts/' + this.$scope.account.id + '/scheduledReports/' + item.id + '/' + path)
                    .search({})
                    .toString();
                return csvDownloadUrl;
            };
            this.$scope.download = (item, path) => {
                if (path === 'queryFile' && !item.lastDate) {
                    return;
                }
                this.$scope.sendFile(this.$scope.csvUrl(item, path));
            };
            this.$scope.sendFile = (url) => {
                location.href = url;
            };
            this.$scope.searchHistory = (params) => {
                return this.$scope.account.customGET('queryAllReportsHistorySearch', params.params);
            };
            // V2
            this.initMenuItems();
        }

        public initMenuItems() {
            this.menuItems = [];
            this.menuItems.push({ type: 'header', name: 'SETUP' });
            _.each(this.baseReports, (item) => {
                this.menuItems.push({ id: item.id, type: 'item', name: item.fullName });
            });
        }
    }
    angular.module('reportCenter').controller('ReportCenterCtrl', ReportCenterCtrl);
}

namespace aq.propertySettings {
    export class BuildingDetailConfigurationCtrl {
        private showManual;
        private months;
        private isAdmin;
        private isSharedBuilding;
        public sizeInSqFt;
        constructor(
            public $scope: ng.IScope,
            public building: aq.common.models.Building,
            public BuildingService,
            public leeds,
            private account,
            private Auth: aq.services.Auth,
            private buildingGroups,
            private UserService
        ) {
            this.showManual = false;
            this.months = [];
            this.isSharedBuilding = this.building.account != this.account.id ? true : false;
            if (this.building.sizeInSqFt) {
                this.building.sizeInSqFt = Math.round(this.building.sizeInSqFt);
            }
            this.isAdmin = UserService.currentUser.userType === 'ADMINISTRATOR';
            moment.months().forEach((month, index) => {
                this.months.push({
                    label: month,
                    value: ++index
                });
            });
            this.$scope.$on('BUILDING_UPDATED', (event: any, context: any) => {
                this.building = context.building;
            });
        }

        queryAddress(searchText) {
            return this.BuildingService.findAddress(searchText);
        }

        selectedItemChange(item) {
            if (item && item.place) {
                const { street, city, stateCode, postalCode, countryCode } = item.place.properties;
                this.building.address = street;
                this.building.city = city;
                this.building.state = stateCode;
                this.building.zipCode = postalCode;
                this.building.country = countryCode;
            }
        }

        toggleManualAddress() {
            this.building.address = null;
            this.building.city = null;
            this.building.state = null;
            this.building.zipCode = null;
            this.building.country = null;
            if (this.showManual) {
                this.showManual = false;
            } else {
                this.showManual = true;
            }
        }

        saveImage() {
            this.building.imageUrl = this.building.imageUrl;
            delete (this.building as any).imageThumbnailUrl;
            this.BuildingService.updateBuilding(this.building);
        }
    }

    angular
        .module('properties')
        .controller('BuildingDetailConfigurationCtrl', BuildingDetailConfigurationCtrl);
}

namespace aq.home {

    export class HomeMainCtrl {

        private acceptCount = 0;
        private currentUserid;

        /* ngInject */
        constructor(
            private $scope,
            private $window: ng.IWindowService,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $modal,
            private $mdDialog: ng.material.IDialogService,
            private Restangular: restangular.IService,
            private Messages: aq.services.Messages,
            private Auth: aq.services.Auth,
            private Errors,
            private mainAppInitialized,
            private loading,
            private UserService,
            private CreateBuildingService: aq.service.CreateBuildingService,
            private $state,
            private $stateParams
        ) {
            if (this.$stateParams.activityId) {
                this.$mdDialog.show({
                    templateUrl: 'app/common/components/activities/modal/modal.html',
                    controller: 'activityModal as vm',
                    clickOutsideToClose: true,
                    locals: {
                        activityId: this.$stateParams.activityId,
                        simpleActivityResponse: null,
                        refreshActivities: () => void 0,
                        accountId: $scope.account.id
                    }
                })
            }
            $scope.year = new Date().getFullYear();
            this.currentUserid = this.UserService.currentUser.id;

            this.mainAppInitialized.promise.then(() => {
                return Restangular.all('users').customGET('me');
            })
                .then((user) => {
                    $scope.user = user;
                    const allAccountBuildings = $scope.account.buildings.concat($scope.account.sharedBuildings);
                    const enrollmentId = this.$location.search().enrollmentId;
                    if (enrollmentId) {
                        this.loading.start();
                        this.$state.go('aq.enrollment', { enrollmentId });
                    } else if ($scope.account && allAccountBuildings == 0) {
                        this.showCreateBuildingModal();
                    }
                });
        }

        filterApps(reportCenterV2: boolean) {
            return (element) => {
                if (element.name.match(/Projects/)) {
                    return this.Auth.hasFunctionality('Aquicore 3.0 Projects');
                }
                if (element.name.match(/Settings/)) {
                    return false;
                }
                if(element.name.match(/Networking/)) {
                    return this.UserService.isAdmin();
                }
                return !(reportCenterV2 && element.name.match(/Report Center/));

            };
        }

        updateInvitations(invitations) {
            this.$scope.invitations = invitations;
            if (invitations.length > 0) {
                this.$scope.invitation = invitations[0];
                this.handleInvitation(this.$scope.invitation);
            }
        }

        public createNewBuilding() {
            this.CreateBuildingService.newBuildingDialog(this.$scope.account);
        }

        showCreateBuildingModal() {
            this.$modal({
                templateUrl: 'app/home/modals/createBuildingModal.html',
                show: true,
                backdrop: 'static',
                scope: this.$scope
            });
        }

        handleInvitation(invitation) {
            this.acceptCount = this.acceptCount + 1;
            this.Restangular.all('accountInvitations')
                .customPOST({}, 'accept', {
                    id: invitation.id,
                    token: invitation.token
                })
                .then(result => {
                    this.Messages.info(result.message, 10);
                    this.updateInvitations(this.$scope.invitations.splice(1));

                    if (this.$scope.invitations.length == 0) {
                        // for now need to refresh the page to have account selector updated
                        if (this.acceptCount > 0) {
                            this.$timeout(() => {
                                this.$window.location.reload();
                            }, 1000);
                        }
                    }
                }, this.Errors.forPromise());
        }
    }

    angular
        .module('home')
        .controller('HomeMainCtrl', HomeMainCtrl);

}


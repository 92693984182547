namespace aq.reports {

    export class PortfolioControllerYTD extends BaseDocraptorReportCtrl {
        public data: BuildingsReportItem[];
        public groupData: BuildingGroupReportItem[];
        public summaryGroupData: DataSummary;
        public currencyUnit;
        public consumptionUnit;
        public peakDemandUnit;
        public reportDateView: string;
        public orderedMonths: { month: number, isCounted: boolean }[];
        public buildingConsumptionActuals;
        public buildingPeakDemandsActuals;
        public buildingBudgetActual;
        public peakVsOffPeak;
        public currentYear: string;
        public personas;
        public reportName: string;
        private week = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        /* ngInject */
        constructor(
            private account,
            private buildings: aq.common.models.Building[],
            private buildingGroups,
            private date,
            private EnergyStarApiService: aq.energyStarApi.EnergyStarApiService,
            private utilitySpendingBudgets,
            private utilitySpendingBudgetsPastYear,
            private buildingTargets: BuildingTargetMap,
            private buildingTargetsPastYear: BuildingTargetMap,
            private buildingConsumptions,
            private buildingSchedulePartitionedConsumptions,
            private buildingPeakDemands,
            private buildingSchedules,
            private PortfolioInsightService: aq.reports.PortfolioInsightService,
            private $filter,
            private OptionsService,
            private buildingPersonnels,
            private buildingOccupancy: { [buildingId: string]: any[] },
            private buildingDegreeDays,
            private reportMonth: string,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            this.personas = {
                'ASSET_MANAGER': 'Asset Manager',
                'PROPERTY_MANAGER': 'Property Manager',
                'BUILDING_ENGINEER': 'Building Engineer'
            };
            const unitKWH = OptionsService.getUnitByEnumName('KWH');
            const unitKW = OptionsService.getUnitByEnumName('KW');
            this.buildingBudgetActual = this.processBuildingDataBudget(buildings, this.utilitySpendingBudgets, this.utilitySpendingBudgetsPastYear);
            this.buildingConsumptionActuals = this.processBuildingData(buildings, this.buildingConsumptions, date, 'sum', unitKWH);
            this.buildingPeakDemandsActuals = this.processBuildingData(buildings, this.buildingPeakDemands, date, 'max', unitKW);
            this.peakVsOffPeak = this.processScheduleConsumptions(unitKWH, unitKW);
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.consumptionUnit = this.OptionsService.getUnitByEnumName('KWH').unit;
            this.peakDemandUnit = this.OptionsService.getUnitByEnumName('KW').unit;
            this.currentYear = moment(this.date).format('YYYY');
            const formattedDate = moment(this.date).format('YYYY-MM-DD');
            const accountDate = moment(formattedDate + 'T00:00:00Z').tz(account.timeZoneId);
            accountDate.subtract(accountDate.utcOffset(), 'minute');
            let currentMonth = moment(accountDate).month() + 1;
            const isMonthInProgress = accountDate.date() != 1;
            if (isMonthInProgress) {
                currentMonth++;
            }
            const startDate = this.getGlobalReportStartDate();
            accountDate.subtract(1, 'second');
            this.reportDateView = `${startDate.format('M/D/YY')} - ${accountDate.format('M/D/YY')}`;
            this.reportName = `${this.account.accountName} YTD Summary Report through ${accountDate.format('MMM D, YYYY')}`;;

            this.data = [];
            this.groupData = [];
            _.each(buildings, (building) => {
                // Check to see if buildingGroupItem has already been created; if not, create a new building group item.
                let buildingGroupItem: BuildingGroupReportItem = _.find(this.groupData,
                    (group) => building.buildingGroup != null && group.groupId === building.buildingGroup
                        || (building.shared || building.buildingGroup === null) && group.groupId === 0
                );
                // if no buildingGroupItem is returned, create a new one
                if (!buildingGroupItem) {
                    const buildingGroup = _.find(this.buildingGroups, (bg) => bg.id === building.buildingGroup);
                    buildingGroupItem = {
                        groupId: buildingGroup ? buildingGroup.id : 0,
                        groupName: buildingGroup ? buildingGroup.name : 'No Group',
                        groupBuildings: [],
                        summary: this.getEmptyDataSummary()
                    };
                    if (buildingGroup && buildingGroup.isIncomplete) {
                        buildingGroupItem.summary.isIncomplete = true;
                    }
                    this.groupData.push(buildingGroupItem);
                }
                const budgetTargets: MeasureTarget[] = this.getTargets(building, 'BUDGET');
                const consumptionTargets: MeasureTarget[] = this.getTargets(building, 'CONSUMPTION');
                const peakDemandTargets: MeasureTarget[] = this.getTargets(building, 'PEAK_DEMAND');
                const consumptionActual = _.find(this.buildingConsumptionActuals,
                    (actual) => actual.buildingId == building.id
                );
                const budgetActual = _.find(this.buildingBudgetActual,
                    (actual) => actual.buildingId == building.id
                );
                const peakDemandActual = _.find(this.buildingPeakDemandsActuals,
                    (actual) => actual.buildingId == building.id
                );
                const buildingReportItem: BuildingsReportItem = {
                    building: this.getBuildingViewItem(building),
                    pastMonthDataItems: [],
                    futureMonthDataItems: [],
                    summary: null
                };
                this.orderedMonths = this.PortfolioInsightService.getOrderedMonths(building, currentMonth);
                const monthlyBudgetTargets = budgetTargets ? this.PortfolioInsightService.getMonthlyTargetsAggregate(budgetTargets) : {};
                const monthlyConsumptionTargets = consumptionTargets ? this.PortfolioInsightService.getMonthlyTargetsAggregate(consumptionTargets) : {};
                const monthlyPeakDemandTargets = peakDemandTargets ? this.PortfolioInsightService.getMonthlyTargetsAggregate(peakDemandTargets) : {};

                const buildingPeakOffPeakData = _.find(this.peakVsOffPeak, (data) => data.buildingId == building.id);

                const summary: BuildingDataSummary = this.getEmptyBuildingDataSummary();
                if (building.size) {
                    summary.sqft = building.size;
                }
                if (buildingPeakOffPeakData) {
                    summary.pieData.data = buildingPeakOffPeakData.pieData;
                }
                if (!building.dataCommissionDate) {
                    summary.hasNotCommissionedBuilding = true;
                }
                const occupancy = this.buildingOccupancy[building.id] ? this.buildingOccupancy[building.id] : null;
                const orderedMonthsWithDate = [];
                let currentOccupancy = '';
                _.each(this.orderedMonths, (monthItem) => {
                    const item = angular.copy(monthItem);
                    const monthOffset = orderedMonthsWithDate.length;
                    const normalizedDate = moment(moment(startDate).add(monthOffset, 'month').format('YYYY-MM-DD'));
                    angular.extend(item, { date: normalizedDate });
                    orderedMonthsWithDate.push(item);
                });
                this.getOccupancyForMonths(orderedMonthsWithDate, occupancy);
                const buildingDegreeDaysData = _.find(this.buildingDegreeDays, (item) => item.buildingId == building.id);
                _.each(this.orderedMonths, (monthItem, index) => {
                    const item: BuildingMonthReportItem = {
                        buildingId: building.id as any,
                        month: monthItem.month,
                        isCounted: monthItem.isCounted,
                        monthDisplay: moment().month(monthItem.month - 1).format('MMM'),
                        budget: this.getEmptyReportItem(),
                        consumption: this.getEmptyReportItem(),
                        peakDemand: this.getEmptyReportItem(),
                        occupancy: orderedMonthsWithDate[index].occupancy ? orderedMonthsWithDate[index].occupancy + '%' : '-',
                        degreeDays: buildingDegreeDaysData && monthItem.isCounted ? this.getDegreeDays(buildingDegreeDaysData, index) : { hdd: null, cdd: null }
                    };
                    if (item.isCounted) {
                        currentOccupancy = item.occupancy;
                    }
                    const month = monthItem.month < 10 ? '0' + monthItem.month : monthItem.month.toString();
                    item.budget.actual = budgetActual.budget[month] ? budgetActual.budget[month] : 0;
                    item.budget.target = monthlyBudgetTargets[month] ? monthlyBudgetTargets[month] : 0;
                    item.consumption.actual = consumptionActual.monthData[month] ? consumptionActual.monthData[month] : 0;
                    item.consumption.target = monthlyConsumptionTargets[month] ? monthlyConsumptionTargets[month] : 0;
                    item.peakDemand.actual = peakDemandActual.monthData[month] ? peakDemandActual.monthData[month] : 0;
                    item.peakDemand.target = monthlyPeakDemandTargets[month] ? monthlyPeakDemandTargets[month] : 0;

                    this.processReportItem(item.budget);
                    this.processReportItem(item.consumption);
                    this.processReportItem(item.peakDemand);
                    if (monthItem.isCounted) {
                        buildingReportItem.pastMonthDataItems.push(item);
                    } else {
                        buildingReportItem.futureMonthDataItems.push(item);
                    }
                    if (item.isCounted) {
                        if (!item.budget.actual || !item.consumption.actual || !item.peakDemand.actual ||
                            !item.budget.target || !item.consumption.target || !item.peakDemand.target) {
                            summary.isDataMissing = true;
                            buildingGroupItem.summary.isDataMissing = true;
                        }
                    }
                    summary.budget.actual += item.isCounted && item.budget.actual ? item.budget.actual : 0;
                    summary.budget.target += item.isCounted && item.budget.target ? item.budget.target : 0;
                    summary.consumption.actual += item.isCounted && item.consumption.actual ? item.consumption.actual : 0;
                    summary.consumption.target += item.isCounted && item.consumption.target ? item.consumption.target : 0;
                    summary.peakDemand.actual += item.isCounted && item.peakDemand.actual > item.peakDemand.target ? 1 : 0;
                    summary.peakDemand.target += item.isCounted ? 1 : 0;
                    summary.degreeDays.hdd += item.degreeDays.hdd ? item.degreeDays.hdd : 0;
                    summary.degreeDays.cdd += item.degreeDays.cdd ? item.degreeDays.cdd : 0;
                });
                summary.occupancy = currentOccupancy;
                this.processSummaryItemBudget(summary.budget);
                this.processSummaryItemConsumption(summary.consumption);
                switch (summary.peakDemand.actual) {
                    case 0:
                        summary.peakDemand.color = this.getColor(95);
                        break;
                    case 1:
                        summary.peakDemand.color = this.getColor(99);
                        break;
                    default:
                        summary.peakDemand.color = this.getColor(101);
                        break;
                }
                summary.peakDemand.description = this.getPeakDemandDescription(summary.peakDemand);
                buildingReportItem.summary = summary;

                const numberOfMonths = buildingReportItem.pastMonthDataItems.length;
                if (buildingReportItem.building.sqft > 0 && numberOfMonths > 0) {
                    const budget = buildingReportItem.summary.budget.actual;
                    const consumption = buildingReportItem.summary.consumption.actual;
                    const sqft = buildingReportItem.building.sqft;
                    buildingReportItem.summary.sqftConsumptionProjection = consumption * 12 / numberOfMonths / sqft;
                    buildingReportItem.summary.sqftBudgetProjection = budget * 12 / numberOfMonths / sqft;
                    buildingReportItem.summary.sqft = sqft;
                }
                this.data.push(buildingReportItem);
                this.sumUpBuildingGroupItem(buildingGroupItem, buildingReportItem);
                buildingGroupItem.groupBuildings.push(buildingReportItem);
            });
            this.summaryGroupData = {
                budget: this.getEmptySummaryItem(),
                consumption: this.getEmptySummaryItem(),
                peakDemand: this.getEmptySummaryItem(),
                sqftBudgetProjection: 0,
                sqftConsumptionProjection: 0,
                sqft: 0,
                budgetSqft: 0,
                consumptionSqft: 0,
                isIncomplete: false,
                isDataMissing: false,
                hasNotCommissionedBuilding: false
            };
            const ts = this.summaryGroupData;
            _.each(this.groupData, (groupItem: BuildingGroupReportItem) => {
                const gs = groupItem.summary;
                this.processSummaryItemBudget(gs.budget);
                this.processSummaryItemConsumption(gs.consumption);
                gs.peakDemand.description = this.getPeakDemandDescription(gs.peakDemand);
                if (gs.sqftBudgetProjection > 0) {
                    ts.sqftBudgetProjection = (ts.sqftBudgetProjection * ts.budgetSqft + gs.sqftBudgetProjection * gs.budgetSqft) / (gs.budgetSqft + ts.budgetSqft);
                    ts.budgetSqft += gs.budgetSqft;
                }
                if (gs.sqftConsumptionProjection > 0) {
                    ts.sqftConsumptionProjection = (ts.sqftConsumptionProjection * ts.consumptionSqft + gs.sqftConsumptionProjection * gs.consumptionSqft) / (gs.consumptionSqft + ts.consumptionSqft);
                    ts.consumptionSqft += gs.consumptionSqft;
                }
                ts.sqft += gs.sqft;
                ts.budget.actual += gs.budget.actual;
                ts.budget.target += gs.budget.target;
                ts.consumption.actual += gs.consumption.actual;
                ts.consumption.target += gs.consumption.target;
                if (gs.isIncomplete) {
                    ts.isIncomplete = true;
                }
                if (gs.hasNotCommissionedBuilding) {
                    ts.hasNotCommissionedBuilding = true;
                }
            });
            this.processReportItem(this.summaryGroupData.budget);
            this.processReportItem(this.summaryGroupData.consumption);

            this.notifyDocumentReady();
        }
        public getTargets(building: Building, targetType: string): MeasureTarget[] {
            const measureTargets: MeasureTarget[] = this.buildingTargets[building.id]
                ? _.filter(this.buildingTargets[building.id], (target: MeasureTarget) => target.type == targetType)
                : [];
            const queryDate = moment(this.date);
            const fiscalYearStart = this.getFiscalYearStart(building, queryDate);
            const isPastDataRequired = moment(fiscalYearStart).year() < queryDate.year() && queryDate.dayOfYear() != 1;

            if (isPastDataRequired) {
                const measureTargetsPastYear: MeasureTarget[] = this.buildingTargetsPastYear[building.id]
                    ? _.filter(this.buildingTargetsPastYear[building.id], (target: MeasureTarget) => target.type == targetType)
                    : [];
                // for current year, clear entries for months after fiscal start
                _.each(measureTargets, (target: MeasureTarget) => {
                    if (!target) {
                        return;
                    }
                    _.each(target.targetItems, (item: TargetItem) => {
                        const month = parseInt(item.startDate.substring(5, 7));
                        if (month >= building.fiscalStartMonth) {
                            item.value = 0;
                        }
                    });
                });
                // for past year, clear entries for months before fiscal start
                _.each(measureTargetsPastYear, (target: MeasureTarget) => {
                    if (!target) {
                        return;
                    }
                    _.each(target.targetItems, (item: TargetItem) => {
                        const month = parseInt(item.startDate.substring(5, 7));
                        if (month < building.fiscalStartMonth) {
                            item.value = 0;
                        }
                    });
                });
                return [...measureTargets, ...measureTargetsPastYear];
            }
            else return measureTargets;
        }
        public getDegreeDays(buildingData, index) {
            const data = buildingData.degreeDays;
            let hdd = data && data.hdd && data.hdd.values ? data.hdd.values[index] : null;
            if (hdd) {
                hdd = Math.round(hdd);
            }
            let cdd = data && data.cdd && data.cdd.values ? data.cdd.values[index] : null;
            if (cdd) {
                cdd = Math.round(cdd);
            }
            return { hdd, cdd };
        }
        public getPeakDemandDescription(peakDemand: DataSummaryItem) {
            return `${peakDemand.actual} of ${peakDemand.target} peaks missed`;
        }
        public getOccupancyForMonths(monthItems, occupancy) {
            if (!occupancy || !occupancy.length) {
                return;
            }
            const sortedOccupancy = _.sortBy(occupancy, 'startDate');
            let index = 0;
            const length = sortedOccupancy.length;
            let current = sortedOccupancy[0];
            _.each(monthItems, (monthItem, i) => {
                if (monthItem.date.isBefore(moment(current.startDate).startOf('month'))) {
                    monthItem.occupancy = i > 0 ? monthItems[i - 1].occupancy : null;
                    return;
                }
                while (index < length - 1 && moment(current.startDate).startOf('month').isSameOrBefore(monthItem.date)) {
                    monthItem.occupancy = current.occupancyPercent;
                    current = sortedOccupancy[++index];
                }
                if (index == length - 1 && moment(current.startDate).startOf('month').isSameOrBefore(monthItem.date)) {
                    monthItem.occupancy = current.occupancyPercent;
                }
            });
        }
        public getGlobalReportStartDate() {
            const queryDate = moment(this.date);
            let minDate = null;
            _.each(this.buildings, (building) => {
                const fiscalYearStart = this.getFiscalYearStart(building, queryDate);
                const tsFiscalYearStart = moment(fiscalYearStart);
                if (minDate == null || tsFiscalYearStart.isBefore(minDate)) {
                    minDate = tsFiscalYearStart;
                }
            });
            return minDate;
        }
        public getBuildingViewItem(building: aq.common.models.Building): aq.reports.BuildingView {
            const buildingState = building.state ? building.state.replace('District of Columbia', '') : '';
            const buildingPersonnel = this.getBuildingPersonnel(building);
            let energyStarScore = null;
            Promise.resolve(this.EnergyStarApiService.getEnergyStarScoreForBuilding(this.account.id, building.id))
                .then((resp) => {
                    energyStarScore = resp.score
                }
            );
            return {
                id: building.id as any,
                name: building.name,
                imageUrl: building.imageUrl,
                streetAddress: building.address,
                cityAddress: `${building.city}, ${buildingState} ${building.zipCode}`,
                energyStarScore: energyStarScore,
                fiscalStartMonth: building.fiscalStartMonth,
                sqft: building.size ? building.size : 0,
                personnel: buildingPersonnel,
                dataCommissionDate: building.dataCommissionDate
            };
        }
        public getBuildingPersonnel(building: aq.common.models.Building) {
            const data = _.find(this.buildingPersonnels, { buildingId: building.id });
            if (data && data.personnel && data.personnel.length > 0) {
                _.each(data.personnel, (item) => {
                    if (!item.email) {
                        return;
                    }
                    const emailSplit = item.email.split('@');
                    if (emailSplit.length != 2) {
                        return;
                    }
                    item.emailUsername = emailSplit[0];
                    item.emailDomain = '@' + emailSplit[1];
                });
                const sortedPersonnel = [];
                _.each(this.personas, (persona, key) => {
                    const nextPerson = _.find(data.personnel, (item) => item.persona == key);
                    if (nextPerson) {
                        sortedPersonnel.push(nextPerson);
                    } else {
                        sortedPersonnel.push({
                            persona: key,
                            name: '-'
                        });
                    }
                });
                return sortedPersonnel;
            } else {
                return [];
            }
        }
        public processScheduleConsumptions(unitEnergy, unitPower) {
            const result = _.map(this.buildingSchedulePartitionedConsumptions, (data) => {
                const startValue = moment(data.start).valueOf();
                const endValue = moment(data.end).valueOf();
                const building = _.find(this.buildings, (b) => b.id == data.id);
                const peakDemandDataMonths = _.compact(_.map(this.buildingPeakDemands, (bp) => bp[building.id]));
                const scheduleRules = [];
                const buildingSchedule = _.find(this.buildingSchedules, (s) => s.buildingId == building.id);
                const rules = buildingSchedule && buildingSchedule.schedule ? buildingSchedule.schedule.rules : [];
                _.each(rules, (rule) => {
                    angular.extend(rule, {
                        startTimeHours: rule.timeStart ? parseInt(rule.timeStart.substr(0, 2)) : 0,
                        startTimeMinutes: rule.timeStart ? parseInt(rule.timeStart.substr(3, 2)) : 0,
                        endTimeHours: rule.timeEnd ? parseInt(rule.timeEnd.substr(0, 2)) : 23,
                        endTimeMinutes: rule.timeEnd ? parseInt(rule.timeEnd.substr(3, 2)) : 59,
                        consumption: 0,
                        monthPeaks: {},
                        avgMaxPeak: 0
                    });
                    scheduleRules.push(rule);
                });
                const filteredScheduleRules = scheduleRules.filter(sr => {
                    const ytdStart = moment(moment().startOf('year').format('MM/DD/YYYY'), 'MM/DD/YYYY');
                    const ytdEnd = moment(moment().startOf('day').format('MM/DD/YYYY'), 'MM/DD/YYYY');
                    if (sr.dateStart === null && sr.dateEnd === null && sr.timeStart === null && sr.timeEnd === null) {
                        return true;
                    }
                    return this.isBetweenStartAndEnd(ytdStart, ytdEnd, sr.dateStart, sr.dateEnd) || (sr.timeStart !== null || sr.timeEnd !== null);
                });
                _.each(filteredScheduleRules, (rule) => {
                    const potentialPeakTsValues = [];
                    _.each(peakDemandDataMonths, (peakDemandData) => {
                        _.each(peakDemandData.timestamps, (ts, i) => {
                            if (ts < startValue || ts > endValue) {
                                return;
                            }
                            const momentTs = moment(ts).tz(building.timeZoneId);
                            const weekday = this.week[momentTs.day()];
                            if (rule.week && !_.includes(rule.week, weekday)) {
                                return;
                            }
                            // If the rule has a date range, use that to exempt timestamps
                            if (rule.dateStart) {
                                const ruleStart = `${rule.dateStart} ${rule.startTimeHours}:${rule.startTimeMinutes}:00`;
                                const startDate = moment.tz(ruleStart, 'YYYY-MM-DD hh:mm:ss', building.timeZoneId);
                                if (momentTs.isBefore(startDate)) {
                                    return;
                                }
                            }
                            if (rule.dateEnd) {
                                const ruleEnd = `${rule.dateEnd} ${rule.endTimeHours}:${rule.endTimeMinutes}:00`;
                                const endDate = moment.tz(ruleEnd, 'YYYY-MM-DD hh:mm:ss', building.timeZoneId);
                                if (momentTs.isAfter(endDate)) {
                                    return;
                                }
                            }
                            potentialPeakTsValues.push({
                                momentTs,
                                dateIdentifier: parseInt(momentTs.format('YYYYMMDD')),
                                value: peakDemandData.values[i]
                            });
                        });
                    });
                    if (potentialPeakTsValues.length > 0) {
                        this.matchDataToRule(rule, potentialPeakTsValues, 'POWER');
                    }
                });
                const scheduleDataPartitions = _.filter(data.partitions, (category) => {
                    return _.some(filteredScheduleRules, (rule) => rule.category == category.id);
                });
                _.each(scheduleDataPartitions, (category) => {
                    const categoryRules = _.filter(filteredScheduleRules, (rule) => rule.category == category.id);
                    const monthPeaksAgg = _.first(categoryRules).monthPeaks;
                    _.each(categoryRules, (rule) => {
                        _.each(rule.monthPeaks, (item, key) => {
                            if (!monthPeaksAgg[key] || item > monthPeaksAgg[key]) {
                                monthPeaksAgg[key] = item;
                            }
                        });
                    });
                    category.monthPeaks = monthPeaksAgg;
                });
                _.each(scheduleDataPartitions, (category) => {
                    category.consumptionView = this.$filter('toUnit')(category.values.total, unitEnergy);
                    category.avgMaxPeak = _.chain(category.monthPeaks).map().mean().value();
                    if (isNaN(category.avgMaxPeak)) {
                        category.avgMaxPeak = 0;
                    }
                    category.avgMaxPeakView = this.$filter('toUnit')(category.avgMaxPeak, unitPower);
                });
                return {
                    buildingId: building.id,
                    pieData: _.map(scheduleDataPartitions, (category) => {
                        return {
                            label: category.name,
                            data: category.values.total,
                            color: category.color,
                            consumption: category.consumptionView,
                            avgMaxPeak: category.avgMaxPeakView
                        };
                    })
                };
            });
            return result;
        }
        public processBuildingDataBudget(buildings, data, pastData) {
            const result = [];
            const queryDate = moment(this.date);
            _.each(buildings, (building, index) => {
                const fiscalYearStart = this.getFiscalYearStart(building, queryDate);
                const tsFiscalYearStart = moment(fiscalYearStart);
                const isPastDataRequired = moment(fiscalYearStart).year() < queryDate.year();
                const buildingData = data[index];

                let pastBuildingData;
                if (isPastDataRequired && pastData) {
                    pastBuildingData = pastData[index];
                }
                if (!buildingData && (!isPastDataRequired || !pastBuildingData)) {
                    result.push({
                        buildingId: building.id,
                        budget: {}
                    });
                    return;
                }
                const currentBuildingBudgetActual = {};
                if (isPastDataRequired && pastBuildingData) {
                    this.processActualBudgetsForCurrentBuilding(currentBuildingBudgetActual, pastBuildingData, queryDate, tsFiscalYearStart, building);
                }
                this.processActualBudgetsForCurrentBuilding(currentBuildingBudgetActual, buildingData, queryDate, tsFiscalYearStart, building);
                result.push({
                    buildingId: building.id,
                    budget: currentBuildingBudgetActual
                });
            });
            return result;
        }
        public processBuildingData(buildings, reponseData, date, action, unit) {
            const result = [];
            _.each(buildings, (building) => {
                _.each(reponseData, (monthDataItem) => {
                    let buildingData = _.find(result, (item) => item.buildingId == building.id);
                    if (!buildingData) {
                        buildingData = {
                            buildingId: building.id,
                            monthData: {}
                        };
                        result.push(buildingData);
                    }
                    const fiscalYearStart = moment(this.getFiscalYearStart(building, moment(date)));
                    const responseBuildingData = monthDataItem[building.id];
                    if (!responseBuildingData) {
                        return;
                    }
                    _.each(responseBuildingData.timestamps, (timestamp, index) => {
                        const ts = moment(timestamp);
                        if (ts.isBefore(fiscalYearStart) || ts.isAfter(moment(date))) {
                            return;
                        }
                        const month = ts.tz(building.timeZoneId).format('MM');
                        if (!buildingData.monthData[month]) {
                            buildingData.monthData[month] = 0;
                        }
                        const value = responseBuildingData.values[index];
                        switch (action) {
                            case 'sum':
                                buildingData.monthData[month] += value ? value : 0;
                                break;
                            case 'max':
                                if (value > buildingData.monthData[month]) {
                                    buildingData.monthData[month] = value;
                                }
                                break;
                            default:
                                break;
                        }
                    });
                });
            });
            _.each(result, (item) => {
                _.each(item.monthData, (value, key) => {
                    item.monthData[key] = this.$filter('toUnit')(item.monthData[key], unit);
                });
            });
            return result;
        }
        public getFiscalYearStart(building, currentDate: moment.Moment) {
            const currentMonth = currentDate.month() + 1;
            const startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            let startYear = currentDate.year();
            if (currentMonth <= startMonth) {
                startYear--;
            }
            return this.getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
        };
        public getBuildingTimezoneFirstDateOfMonth = (building, year, month) => {
            const yearStartString = `${year}-${month < 10 ? 0 : ''}${month}-01T00:00:00Z`;
            const offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
            const start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
            return start;
        };
        public sumUpBuildingGroupItem(groupItem: BuildingGroupReportItem, buildingItem: BuildingsReportItem) {
            const gs = groupItem.summary;
            const bs = buildingItem.summary;
            if (bs.sqft) {
                if (bs.sqftBudgetProjection > 0) {
                    gs.sqftBudgetProjection = (gs.sqftBudgetProjection * gs.budgetSqft + bs.sqftBudgetProjection * bs.sqft) / (gs.budgetSqft + bs.sqft);
                    gs.budgetSqft += bs.sqft;
                }
                if (bs.sqftConsumptionProjection > 0) {
                    gs.sqftConsumptionProjection = (gs.sqftConsumptionProjection * gs.consumptionSqft + bs.sqftConsumptionProjection * bs.sqft) / (gs.consumptionSqft + bs.sqft);
                    gs.consumptionSqft += bs.sqft;
                }
            }
            if (bs.hasNotCommissionedBuilding) {
                gs.hasNotCommissionedBuilding = true;
            }
            gs.sqft += buildingItem.building.sqft;
            gs.budget.actual += bs.budget.actual;
            gs.budget.target += bs.budget.target;
            gs.consumption.actual += bs.consumption.actual;
            gs.consumption.target += bs.consumption.target;
            gs.peakDemand.actual += bs.peakDemand.actual;
            gs.peakDemand.target += bs.peakDemand.target;
        }
        public processReportItem(item: VarianceReportItem) {
            item.diff = item.target - item.actual;
            item.variance = item.target ? Math.abs(Math.round((item.target - item.actual) * 1000 / item.target) / 10) : 0;
            item.percentageOfGoal = item.target ? Math.round(item.actual * 1000 / item.target) / 10 : 0;
        }
        public processSummaryItemBudget(item: DataSummaryItem) {
            this.processSummaryItem(item);
            if (item && item.target) {
                item.description = `${this.filterCharge(item.actual)} of ${this.filterCharge(item.target)}`;
            }
        }
        public processSummaryItemConsumption(item: DataSummaryItem) {
            this.processSummaryItem(item);
            if (item && item.target) {
                item.description = `${this.filterConsumption(item.actual)} of ${this.filterConsumption(item.target)}`;
            }
        }
        public processSummaryItem(item: DataSummaryItem) {
            this.processReportItem(item);
            item.color = this.getColor(item.variance);
        }
        public getColor(percent) {
            if (percent > 100) {
                return '#E70F28';
            } else if (percent <= 100 && percent >= 98) {
                return '#F1A81D';
            } else if (percent < 98) {
                return '#7ACD46';
            }
        }

        private isBetweenStartAndEnd(ytdStart: moment.Moment, ytdEnd: moment.Moment, ruleStart: string, ruleEnd: string): boolean {
            if (ruleStart !== null && ruleEnd !== null) {
                return (ytdStart.isSame(ruleStart) || ytdStart.isBefore(ruleStart)) &&
                    (ytdEnd.isSame(ruleEnd) || ytdEnd.isAfter(ruleEnd));
            } else if (ruleStart !== null) {
                return ytdStart.isSame(ruleStart) || ytdStart.isBefore(ruleStart);
            } else if (ruleEnd !== null) {
                return ytdEnd.isSame(ruleEnd) || ytdEnd.isAfter(ruleEnd);
            }
            return false;
        }

        private processActualBudgetsForCurrentBuilding(currentBuildingBudgetActual, buildingData, queryDate, tsFiscalYearStart, building) {
            _.each(buildingData.spending.data, (data) => {
                const ts = moment(data.localDate, 'MM/DD/YYYY');
                const tsFormattedFiscalYearStart = moment(tsFiscalYearStart.format('MM/DD/YYYY'), 'MM/DD/YYYY');
                if (!ts.isBefore(queryDate) || ts.isBefore(tsFormattedFiscalYearStart)) {
                    return;
                }
                const month = ts.format('MM');
                if (!currentBuildingBudgetActual[month]) {
                    currentBuildingBudgetActual[month] = 0;
                }
                const value = data.datum.consumption || data.datum.utilityBill || 0;
                currentBuildingBudgetActual[month] = value ? value : 0;
            });
        }
        private filterCharge(charge) {
            return this.$filter('formatCharge')(charge, this.currencyUnit, false);
        }
        private filterConsumption(charge) {
            return this.$filter('formatUsage')(charge, this.consumptionUnit);
        }
        private getEmptyDataSummary() {
            const item: DataSummary = {
                sqft: 0,
                budgetSqft: 0,
                consumptionSqft: 0,
                sqftBudgetProjection: 0,
                sqftConsumptionProjection: 0,
                budget: this.getEmptySummaryItem(),
                consumption: this.getEmptySummaryItem(),
                peakDemand: this.getEmptySummaryItem(),
                isIncomplete: false,
                isDataMissing: false,
                hasNotCommissionedBuilding: false
            };
            return item;
        }
        private getEmptyBuildingDataSummary() {
            const item: BuildingDataSummary = this.getEmptyDataSummary() as BuildingDataSummary;
            angular.extend(item, {
                pieData: { data: null },
                degreeDays: { hdd: 0, cdd: 0 },
                occupancy: ''
            });
            return item;
        }
        private getEmptyReportItem() {
            const item: VarianceReportItem = {
                actual: 0,
                target: 0,
                diff: 0,
                variance: 0,
                description: '',
                percentageOfGoal: 0
            };
            return item;
        }
        private getEmptySummaryItem() {
            const item: DataSummaryItem = this.getEmptyReportItem() as any;
            item.color = '';
            return item;
        }
        private matchDataToRule(rule, tsValues, type) {
            const len = tsValues.length;
            let item = tsValues[0];
            let i = 1;
            let lastDateIdentifier = item.dateIdentifier;
            while (i < len) {
                while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() < rule.startTimeHours) {
                    item = tsValues[i++];
                }
                if (lastDateIdentifier != item.dateIdentifier) {
                    lastDateIdentifier = item.dateIdentifier;
                    continue;
                }
                while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() == rule.startTimeHours && item.momentTs.minute() < rule.startTimeMinutes) {
                    item = tsValues[i++];
                }
                if (lastDateIdentifier != item.dateIdentifier) {
                    lastDateIdentifier = item.dateIdentifier;
                    continue;
                }
                while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() < rule.endTimeHours) {
                    this.processRuleData(rule, item, type);
                    item = tsValues[i++];
                    if (lastDateIdentifier == item.dateIdentifier && i == len && item.momentTs.hour() < rule.endTimeHours) {
                        this.processRuleData(rule, item, type);
                    }
                }
                if (lastDateIdentifier != item.dateIdentifier) {
                    lastDateIdentifier = item.dateIdentifier;
                    continue;
                }
                while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() == rule.endTimeHours && item.momentTs.minute() < rule.endTimeMinutes) {
                    this.processRuleData(rule, item, type);
                    item = tsValues[i++];
                    if (lastDateIdentifier == item.dateIdentifier && i == len && item.momentTs.hour() == rule.endTimeHours && item.momentTs.minute() < rule.endTimeMinutes) {
                        this.processRuleData(rule, item, type);
                    }
                }
                if (lastDateIdentifier != item.dateIdentifier) {
                    lastDateIdentifier = item.dateIdentifier;
                    continue;
                }
                while (lastDateIdentifier == item.dateIdentifier && i < len) {
                    item = tsValues[i++];
                }
                if (i < len) {
                    lastDateIdentifier = item.dateIdentifier;
                }
            }
        };
        private processRuleData(rule, tsValueItem, type) {
            switch (type) {
                case 'ENERGY':
                    rule.consumption += tsValueItem.value;
                    break;
                case 'POWER':
                    const month = tsValueItem.momentTs.format('MM');
                    if (!rule.monthPeaks[month]) {
                        rule.monthPeaks[month] = tsValueItem.value;
                    } else if (rule.monthPeaks[month] < tsValueItem.value) {
                        rule.monthPeaks[month] = tsValueItem.value;
                    }
                    break;
                default:
                    break;
            }
        };
    }

    angular
        .module('aq.reports')
        .controller('PortfolioControllerYTD', PortfolioControllerYTD);
}

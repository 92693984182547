angular.module('properties',
    [
        'aq.ui.confirmation',
        'ui.select2',
        'aq.ui',
        'aq.services.options',
        'aq.filters.common',
        'ui.calendar',
        'aq.calendar',
        'aq.linechart',
        'aq.tooltip',
        'infinite-scroll-pager',
        'aq.sortable',
        'aq.ui.listSelector',
        'aq.auth',
        'aq.services.filepicker',
        'aq.ui.elemSelector',
        'aq.services.restangular',
        'aq.services.options',
        'aq.calendar.widget',
        'aq.ui.image',
        'aq.filereader',
        'aq.services',
        'ngAnimate',
        'properties.sources',
        'properties.dataCollectors',
        'angular-timeline',
        'aq.filters.units'
    ])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.properties', {
                url: '/accounts/:accountId/property-settings',
                templateUrl: 'app/properties/main/main.html',
                controller: 'PropertiesCtrl',
                abstract: true,
                data: {
                    appName: 'Property Settings'
                },
                resolve: {
                    authAccess(waitForAuthToken, Auth) {
                        return Auth.access;
                    },
                    accountId: ($stateParams: ng.ui.IStateParamsService) => {
                        return $stateParams.accountId;
                    },
                    buildings: (waitForAuthToken, RestangularV3: restangular.IService) => {
                        return RestangularV3.all('buildings').getList();
                    }
                }
            })
            .state('aq.properties.buildings', {
                template: '<ui-view></ui-view>',
                url: '/buildings',
                resolve: {
                    account: (Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService, waitForAuthToken) => {
                        return Restangular.one('accounts', $stateParams.accountId).get({ single: true });
                    },
                    leeds: (Restangular: restangular.IService, waitForAuthToken) => {
                        return Restangular.one('options', 'leeds').getList();
                    },
                    temperatureUnit: (OptionsService, account: aq.common.models.Account) => {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(() => {
                            return OptionsService.temperatureUnit();
                        });
                    },
                    readOnlyForm: (Auth, waitForAuthToken) => {
                        return !Auth.check({ appName: 'Property Settings', access: 'EDIT' });
                    }
                }
            })
            .state('aq.properties.buildings.form', {
                url: '/:buildingId/building',
                templateUrl: 'app/properties/building/buildingDetail.html',
                controller: 'BuildingDetailCtrl as vm',
                data: {
                    breadcrumb: 'Building',
                    icon: '.aq-icons-buildings'
                },
                resolve: {
                    building: (
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        accountId: number,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return DataStore.get(RestangularV3.one('buildings', $stateParams.buildingId), {}, true);
                    },
                    energyStarScore: (
                        accountId: number,
                        EnergyStarApiService: aq.energyStarApi.EnergyStarApiService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return EnergyStarApiService.getEnergyStarScoreForBuilding(accountId.toString(), $stateParams.buildingId);
                    },
                    occupancies: (
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(RestangularV3.one('building', $stateParams.buildingId), 'buildingOccupancy', {});
                    },
                    weatherNormalization: (
                        Restangular: restangular.IService,
                        accountId: number,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return Restangular.one('accounts', accountId).one('buildings', $stateParams.buildingId)
                            .get({ inflate: 'weatherNormalization', single: true })
                            .then((building) => {
                                return building.weatherNormalization;
                            }, () => {
                                return null;
                            });
                    },
                    baselineYears: (
                        Restangular: restangular.IService,
                        accountId: number,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return Restangular.one('accounts', accountId).one('buildings', $stateParams.buildingId)
                            .customGET('WeatherNormalizations', { nocache: new Date().getTime() }).then((baselineYears) => {
                                return baselineYears;
                            }, () => {
                                return [];
                            });
                    },
                    optimalstart: (
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return DataStore.get(RestangularV3.one('buildings', $stateParams.buildingId).one('optimal-start'));
                    },
                    buildingEnrollment: (
                        Auth,
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return RestangularV3
                            .one('buildings', $stateParams.buildingId)
                            .one('enrollment')
                            .get()
                            .catch(() => { });
                    },
                    buildingGroups: (RestangularV3: restangular.IService, waitForAuthToken) => {
                        return RestangularV3.all('building-groups').getList();
                    },
                    onEnter: (Segment: aq.services.SegmentService) => {
                        Segment.trackEvent('Budgets:Building Basics');
                    }
                }
            })
            .state('aq.properties.buildings.form.configuration', {
                url: '/configuration',
                templateUrl: 'app/properties/building/buildingDetailConfiguration.html',
                controller: 'BuildingDetailConfigurationCtrl as vm',
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Budgets:Building Basics:Configuration');
                }
            })
            .state('aq.properties.buildings.form.occupancy', {
                url: '/occupancy',
                templateUrl: 'app/properties/building/buildingDetailOccupancy.html',
                controller: 'BuildingDetailOccupancyCtrl as vm',
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Budgets:Building Basics:Occupancy');
                }
            })
            .state('aq.properties.buildings.form.personnel', {
                url: '/personnel',
                templateUrl: 'app/properties/building/buildingDetailPersonnel.html',
                controller: 'BuildingDetailPersonnelCtrl as vm',
                resolve: {
                    personnel: (
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(RestangularV3.one('buildings', $stateParams.buildingId), 'personnel', {});
                    },
                    users: (
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return RestangularV3.all('users/users-for-building').getList({ 'buildingId': $stateParams.buildingId, 'showAdmin': false });
                    }
                },
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Budgets:Building Basics:Personnel');
                }
            })
            .state('aq.properties.buildings.form.optimalstart', {
                url: '/optimal-start',
                templateUrl: 'app/properties/building/buildingDetailOptimalStart.html',
                controller: 'BuildingDetailOptimalStartCtrl as vm',
                resolve: {
                    users: (
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return RestangularV3.all('users/users-for-building').getList({ 'buildingId': $stateParams.buildingId, 'showAdmin': false });
                    }
                },
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Budgets:Building Basics:Optimal Start');
                }
            })
            .state('aq.properties.buildings.form.mechanical', {
                url: '/mechanical',
                templateUrl: 'app/properties/building/buildingDetailMechanical.html',
                controller: 'BuildingDetailMechanicalCtrl as vm',
                resolve: {
                    allEnums(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.one('options', 'all-building-enums').get();
                    }
                },
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Budgets:Building Basics:Mechanical');
                }
            })
            .state('aq.properties.buildings.spaces', {
                url: '/:buildingId/spaces',
                templateUrl: 'app/properties/spaces/index.html',
                controller: 'SpacesCtrl',
                data: {
                    hide: false,
                    breadcrumb: 'Spaces',
                    icon: '.aq-icons-spaces'
                },
                resolve: {
                    building: (
                        DataStore: aq.common.DataStore,
                        Restangular: restangular.IService,
                        accountId: number,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return Restangular.one('accounts', accountId).one('buildings', $stateParams.buildingId).get({ single: true });
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Spaces');
                }
            })
            .state('aq.properties.buildings.targets', {
                templateUrl: 'app/properties/targets/list/list.html',
                url: '/:buildingId/targets',
                controller: 'TargetListCtrl as targetListCtrl',
                data: {
                    hide: false,
                    breadcrumb: 'Annual Targets',
                    icon: 'gps_fixed'
                },
                resolve: {
                    building: (
                        DataStore: aq.common.DataStore,
                        Restangular: restangular.IService,
                        accountId: number,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return Restangular.one('accounts', accountId).one('buildings', $stateParams.buildingId).get({ single: true });
                    },
                    measures: (Restangular: restangular.IService, waitForAuthToken) => {
                        return Restangular.all('Measures').getList()
                            .then((measures: aq.common.models.Measure[]) => {
                                return _.filter(measures, (measure) => {
                                    return _.includes(['electricity', 'water', 'gas', 'steam', 'heat'], measure.name);
                                });
                            });
                    },
                    allTargets: (
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(Restangular.one('accounts', $stateParams.accountId).one('buildings', $stateParams.buildingId),
                            'targets', { inflate: 'targetItems,measure' });
                    },
                    types: () => {
                        return [{
                            value: 'PEAK_DEMAND',
                            label: 'Peak Demand'
                        }, {
                            value: 'CONSUMPTION',
                            label: 'Consumption'
                        }, {
                            value: 'BASELOAD',
                            label: 'Baseload'
                        }, {
                            value: 'ENERGY_STAR',
                            label: 'Energy Star'
                        }];
                    },
                    hasBudgetAccess: (Auth, waitForAuthToken) => {
                        return Auth.check({ appName: 'Budgets' });
                    },
                    utilityServices: ($stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService, hasBudgetAccess) => {
                        if (hasBudgetAccess) {
                            return RestangularV3.all('utility-services')
                                .customGET('', { buildingId: $stateParams.buildingId });
                        }
                        return [];
                    },
                    onEnter: (
                        Segment: aq.services.SegmentService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken,
                    ) => {
                        Segment.trackChurnZeroEvent('VIEW_TARGETS', [$stateParams.buildingId]);
                    }
                }
            }).state('aq.properties.buildings.targets.details', {
                templateUrl: 'app/properties/targets/details/details.html',
                url: '/:targetId',
                controller: 'TargetDetailsCtrl as targetDetailsCtrl',
                data: {
                    breadcrumb: 'Target Details',
                    redirectState: 'aq.properties.buildings.targets'
                },
                resolve: {
                    authAccess(waitForAuthToken, Auth) {
                        return Auth.access;
                    },
                    occupancies: (
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(RestangularV3.one('building', $stateParams.buildingId), 'buildingOccupancy', {});
                    },
                    target: (
                        allTargets: aq.propertySettings.Target[],
                        building: aq.common.models.Building,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ) => {
                        return DataStore.get(Restangular.one('accounts', $stateParams.accountId).one('buildings', $stateParams.buildingId)
                            .one('targets', $stateParams.targetId), { inflate: 'targetItems,measure', single: true }, false);
                    }
                }
            }).state('aq.properties.buildings.alerts', {
                templateUrl: 'app/properties/alerts/list/list.html',
                url: '/:buildingId/alerts',
                controller: 'AlertListCtrl as vm',
                data: {
                    hide: false,
                    breadcrumb: 'Alerts',
                    icon: 'add_alert'
                },
                params: {
                    changeTimeStamp: null
                },
                resolve: {
                    building: (
                        DataStore: aq.common.DataStore,
                        Restangular: restangular.IService,
                        accountId: number,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return Restangular.one('accounts', accountId).one('buildings', $stateParams.buildingId).get({ single: true });
                    },
                    allStateAlerts: (
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(
                            RestangularV3.one(''),
                            'state-alerts',
                            { buildingId: $stateParams.buildingId }
                        );
                    },
                    allUsageAlerts: (building: aq.common.models.Building, DataStore: aq.common.DataStore, waitForAuthToken) => {
                        return DataStore.getList(building, 'realTimeEnergyAlerts', { inflate: 'receivers,customRule' });
                    },
                    allRecoveryNotifications: (building: aq.common.models.Building, DataStore: aq.common.DataStore, waitForAuthToken) => {
                        return DataStore.getList(building, 'recoveryNotifications', { inflate: 'receivers,currentState' });
                    },
                    buildingDrillin: (
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(account, 'drillin', { mode: 'METER' }).then((drillin) => {
                            const buildingDrillin = _.find(drillin, { id: building.id });
                            return buildingDrillin;
                        });
                    },
                    tenants: (
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        $filter: ng.IFilterService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(account, 'drillin', { mode: 'TENANT' }).then((drillin) => {
                            const buildingDrillin = _.find(drillin, { id: building.id });
                            const tenants = $filter<Function>('flattenHierarchy')([buildingDrillin], 'children', null);
                            tenants.shift();
                            return tenants;
                        });
                    },
                    sources: (
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        $filter: ng.IFilterService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(account, 'drillin', { mode: 'SOURCE' }).then((drillin) => {
                            const buildingDrillin = _.find(drillin, { id: building.id });
                            const sources = $filter<Function>('flattenHierarchy')([buildingDrillin], 'children', null);
                            sources.shift();
                            return sources;
                        });
                    },
                    collectors: (
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        $filter: ng.IFilterService,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(account, 'drillin', { mode: 'METER' }).then((drillin) => {
                            const buildingDrillin = _.find(drillin, { id: building.id });
                            let meters = $filter<Function>('flattenHierarchy')([buildingDrillin], 'children', null);
                            meters.shift();
                            meters = _.filter(meters, (meter: any) => {
                                return meter.route !== 'points';
                            });
                            return meters;
                        });
                    },
                    calendars: (DataStore: aq.common.DataStore, account: aq.common.models.Account, waitForAuthToken) => {
                        return DataStore.getList(account, 'workCalendars', { inflate: 'rules,rules.category' });
                    },
                    units: (OptionsService, account: aq.common.models.Account) => {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(function () {
                            return OptionsService.getAllUnits();
                        });
                    },
                    users: (RestangularV3: restangular.IService, $stateParams: ng.ui.IStateParamsService, waitForAuthToken) => {
                        return RestangularV3.all('users/users-for-building').getList({ 'buildingId': $stateParams.buildingId, 'showAdmin': false });
                    },
                    notificationSenders: (DataStore: aq.common.DataStore, Restangular: restangular.IService, waitForAuthToken) => {
                        return DataStore.getList(Restangular.one('options'), 'notificationSenders', {}).then((notificationSenders) => {
                            return _.filter(notificationSenders, (sender) => {
                                return sender.notificationType === 'REAL_TIME_ENERGY'
                                    || sender.notificationType === 'GEM_METER_DOWN'
                                    || sender.notificationType === 'STATE';
                            });
                        });
                    },
                    changeTimeStamp: ($stateParams: ng.ui.IStateParamsService) => {
                        return $stateParams.changeTimeStamp;
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Alerts');
                }
            }).state('aq.properties.buildings.alerts.details', {
                templateUrl: 'app/properties/alerts/details/details.html',
                url: '/:alertId',
                controller: 'AlertDetailsCtrl as vm',
                data: {
                    breadcrumb: 'Alert Details',
                    redirectState: 'aq.properties.buildings.alerts'
                },
                params: {
                    isCreate: false
                },
                resolve: {
                    stateAlert: (
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService,
                        waitForAuthToken
                    ) => {
                        return DataStore.get(
                            RestangularV3.one('state-alerts', $stateParams.alertId),
                            {},
                            false
                        );
                    },
                    usageAlert: (
                        allUsageAlerts: aq.propertySettings.RealTimeEnergyAlert,
                        building: aq.common.models.Building,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService
                    ) => {
                        return DataStore.get(
                            building.one('realTimeEnergyAlerts', $stateParams.alertId),
                            { inflate: 'calendar', single: true },
                            false
                        );
                    },
                    recoveryNotification: (
                        allRecoveryNotifications: aq.propertySettings.AbstractNotification[],
                        building: aq.common.models.Building,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        return DataStore.get(
                            building.one('recoveryNotifications', $stateParams.alertId),
                            { inflate: 'customRule,receivers', single: true },
                            false
                        );
                    },
                    isNewAlert: ($stateParams: ng.ui.IStateParamsService) => {
                        return !!$stateParams.isCreate;
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Alerts:Details');
                }
            }).state('aq.properties.buildings.schedule', {
                templateUrl: 'app/properties/schedule/buildingSchedule.html',
                url: '/:buildingId/schedule',
                controller: 'BuildingScheduleCtrl as vm',
                data: {
                    breadcrumb: 'Schedule',
                    icon: 'date_range'
                },
                resolve: {
                    buildingSchedule: (
                        buildings: aq.common.models.Building[],
                        account: aq.common.models.Account,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        const calendarId = _.find(buildings, (b) => b.id == $stateParams.buildingId).calendar;
                        return account.one('buildings', $stateParams.buildingId).one('workCalendars', calendarId);
                    },
                    ruleCategories: (account: aq.common.models.Account) => {
                        return account.all('RuleCategories').getList();
                    },
                    buildingId: ($stateParams: ng.ui.IStateParamsService) => {
                        return $stateParams.buildingId;
                    }
                }
            });
    });

namespace aq.services {
    export class BuildingSelectorHelperService {
        /* @ngInject */
        constructor(
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService,
            private AppStorageService: aq.services.AppStorageService
        ) { }

        public getBuildings() {
            return this.RestangularV3.all('buildings').getList();
        }

        public getBuildingGroups(accountId) {
            return this.RestangularV3.all('building-groups').getList();
        }

        public getBuildingEnrollment(buildingId: number) {
            return this.RestangularV3
                .one('buildings', buildingId)
                .one('enrollment')
                .get();
        }

        public getBuilding(accountId: number): number {
            const storage = this.AppStorageService.getStorage('buildingSelector', accountId);
            if (storage) {
                return storage.buildingId;
            }
            return null;
        }
    }

    angular.module('aq.services').service('BuildingSelectorHelperService', BuildingSelectorHelperService);
}
